<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.users') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.users') }}
        </div>
        <div class="level-right">
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /><span>{{ $t('newUser') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table
        ref="table" :items="items" sort-by="_id"
        :theme="$_ui_theme_tables"
      >
        <data-column field="name" :label="$t('name')" />
        <data-column field="email" :label="$t('email')" />
        <data-column field="scopes" :label="$t('scopes')" width="40%">
          <template slot-scope="{ value }">
            <div class="tags">
              <span v-for="scope in value" :key="scope" class="tag is-primary">{{ scope }}</span>
            </div>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')" class="has-text-centered" :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item)">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </button>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.name || $t('newUser') }}
        </div>

        <form @submit.prevent="submit">
          <div class="field">
            <label class="label">{{ $t('email') }}</label>
            <div class="control">
              <input
                v-model="item.email" class="input" type="text"
                :disabled="item.id" autocomplete="username" required
              >
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('name') }}</label>
            <div class="control">
              <input
                v-model="item.name" class="input" type="text"
                required autocomplete="name"
              >
            </div>
          </div>
          <hr>
          <div class="field">
            <label class="label">{{ $t('password') }}</label>
            <div class="control">
              <input
                v-model="item.password" class="input" type="password"
                autocomplete="new-password"
              >
            </div>
          </div>

          <div v-if="item.email === user.id()" class="field">
            <label class="label">{{ $t('oldPassword') }}</label>
            <div class="control">
              <input
                v-model="item.old_password" class="input" type="password"
                placeholder="Old Password" autocomplete="current-password"
              >
            </div>
          </div>

          <hr>
          <div class="field" @keydown.enter.prevent>
            <label class="label">{{ $t('scopes') }}</label>
            <v-select
              v-model="item.scopes" :options="options" :taggable="true"
              :multiple="true"
            />
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
    <box>
      <data-table
        ref="table"
        sort-by="created_at"
        sort-desc
        :items="activities"
        :theme="$_ui_theme_tables"
        :per-page="20"
      >
        <data-column
          field="created_at"
          :label="$t('time')"
        >
          <template slot-scope="{ value }">
            <span>{{ value | dmyhs }}</span>
          </template>
        </data-column>
        <data-column
          field="user.email"
          :label="$t('user')"
        />
        <data-column
          field="ip_address"
          label="IP"
        />
        <data-column
          field="description"
          :label="$t('description')"
        />
        <data-column
          field="method"
          label="Method"
        />
        <data-column
          field="endpoint"
          :label="$t('endpoint')"
        />
        <data-column
          field="status"
          :label="$t('status')"
        />
      </data-table>
    </box>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal } from '@cyradar/ui'
import { plus, x, pencil } from 'octicons-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: { DataTable, DataColumn, Modal, vSelect },
  data () {
    return {
      modal: false,
      item: null,
      scopes: {}
    }
  },
  computed: {
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    user () {
      return this.$store.getters['authentication/user']
    },
    options () {
      const presets = Object.keys(this.scopes.presets)
      return presets.concat(Object.values(this.scopes.permissions).map(x => {
        return x.map(y => `${y.action}:${y.object}`)
      }).flat())
    }
  },
  mounted () {
    this.loadScopes()
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    create () {
      this.item = {}
      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/users', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    activities (filter, sort, pagination) {
      return this.$http.get(`/api/v1/users/activities?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    },
    store () {
      return this.$http.post('/api/v1/users', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    update () {
      return this.$http.patch(`/api/v1/users/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/users/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    loadScopes () {
      return this.$http.get('/api/v1/users/permissions').then(body => {
        if (!body || !body.data) {
          return
        }

        this.scopes = body.data.data
      })
    }
  }
}
</script>
